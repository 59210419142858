export const APP_CONSTANTS = {
  EMAIL: 'support@refuses.online',
  ADDRESS: '30 N Gould st Ste 4118, Sheridan, WY 82801, USA +8772370608',
  COMPANY_NAME: 'Milky Way Entertainment LLC',
  APP_NAME: 'Refuse',
  APP_SHORT_NAME: 'Refuse',
  WEBSITE_NAME: 'Track beat',
  IOS_DOWNLOAD_APP_LINK:
    'https://apps.apple.com/pt/app/heart-rate-monitor/id1591295637?l=en-GB',
  ANDROID_DOWNLOAD_APP_LINK:
    'https://apps.apple.com/pt/app/heart-rate-monitor/id1591295637?l=en-GB',
} as const;

export const APP_URLS = {
  APP_BASE_PATH: 'https://refuses.online/refuse/',
  DEV_APP_BASE_PATH: 'https://dev.refuses.online/refuse/',
};

export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
